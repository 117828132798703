import React from 'react';
import { StoryblokNodeTree } from '../../services';
import { Props } from '../types';

interface NavigationProps extends Props {
  tree: StoryblokNodeTree[];
  currentCountry: string;
  currentLanguage: string;
  countryCode: string;
  alternates: string;
}

const Navigation = 'rvv-navigation' as React.ElementType;

const cleanSlug = (slug: string) => {
  const slugs = slug.split('/');

  if (slugs[1].includes('-')) return slugs.join('/');
  slugs.splice(1, 1);
  return slugs.join('/');
};

function renderTree(leaf: StoryblokNodeTree): {
  userTypeSlug: string;
  tree: unknown;
} {
  // top level

  const tree = [];
  if (leaf.is_folder) {
    const tabEntry = {
      text: leaf.name,
      href: cleanSlug(leaf.real_path),
      children: [],
    };
    leaf.children.forEach((c) => {
      if (!c.page?.content?.hide_in_navigation) {
        tabEntry.children.push({
          text: c.page?.content?.navigation_title || c.name,
          href: cleanSlug(c.real_path),
          taglist: c.page?.tag_list,
        });
      }
    });
    tree.push(tabEntry);
  } else if (leaf.page && leaf.page?.tag_list.includes('mainMenu')) {
    const tabEntry = {
      text: leaf.name,
      href: cleanSlug(leaf.real_path),
      taglist: leaf.page?.tag_list,
      altText: leaf.page?.content.menu_alt_text,
    };
    tree.push(tabEntry);
  }
  return {
    userTypeSlug: leaf.slug ? leaf.slug : leaf.real_path.substring(1),
    tree,
  };
}

// TODO correct typing of storyblok repsones

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCurrentTree(tree: Map<string, any>, lang = 'at-de'): unknown[] {
  const rvv = tree.get('rvv');
  let currentTree = rvv?.children.filter((s) => s.name.includes(lang.toUpperCase())
  )[0]?.children;
  if (window.location.pathname.includes('/rsi/')) {
    currentTree = rvv?.children.filter((s) => s.name === 'rsi')[0].children.filter((s) => s.name.includes(lang.toUpperCase())
    )[0]?.children;
  }
  return currentTree;
}

export const rvvNavigation = (props: NavigationProps): JSX.Element => {
  const {
    tree, currentCountry, currentLanguage, countryCode, alternates,
  } = props;

  if (!tree) {
    return null;
  }

  // Store the tree in a language map
  const langMap = new Map();
  tree.forEach((t) => {
    const key = t.slug ? t.slug : t.name.toLowerCase().replace(' - ', '-');
    if (!langMap.has(key)) {
      langMap.set(key, t);
    }
  });
  const currentTree = getCurrentTree(langMap, countryCode);

  const items = currentTree ? currentTree.map(renderTree) : [];

  // We need the custom component, otherwise jsx does not render the attributes
  return (
    <Navigation
      tab-entries={JSON.stringify(items)}
      current-language={currentLanguage}
      current-country={currentCountry}
      country-code={countryCode}
      alternates={alternates}
    >
      {/*
       * NOTE: Only works if renderTree is defined using the function keyword!
       *
       * To avoid managing the arguments that are passed to renderTree,
       * we pass component state as the thisArg to the mapping function.
       */}
    </Navigation>
  );
};
