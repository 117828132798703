import React, { Component } from 'react';
import { StoryData } from 'storyblok-js-client';
import { blokToComponent, getComponent } from '../components';
import {
  DomService,
  GlobalConfigProps,
  GlobalContent,
  Language,
  NavigationService,
  StoryblokNodeTree,
  StoryblokService,
} from '../services';

export interface StoryDataFromGraphQLQuery extends StoryData {
  lang: string;
}

export interface EntryData extends GlobalConfigProps {
  story?: StoryDataFromGraphQLQuery;
  navigation?: StoryblokNodeTree[];
  contact?: StoryData;
  languages?: Language[];
  search?: StoryData;
  globalContent?: GlobalContent;
}

interface StoryblokEntryProps {
  pageContext: EntryData;
  location: {
    state?: {
      maskUrl?: string;
    };
  };
}

type StoryblokEntryState = EntryData & { showIEModal: boolean };

const parseEntryData = ({
  pageContext,
}: StoryblokEntryProps): StoryblokEntryState => {
  const story = {
    ...pageContext.story,
  };

  return {
    story,
    showIEModal: false,
    ...DomService.getGlobalConfig(
      story.uuid,
      StoryblokService.getCountryCode(story).locale,
      StoryblokService.getCountryCode(story).country,
      story.full_slug,
    ),
    globalContent: pageContext.globalContent,
  };
};

// Datasources
const RvvGlobalConfig = getComponent('rvv-global-config') as React.ElementType;
const RvvGlobalContent = getComponent(
  'rvv-global-content',
) as React.ElementType;
const Navigation = getComponent('rvv-navigation') as React.ElementType;
const Footer = getComponent('rvv-footer') as React.ElementType;
const Container = 'rvv-layout-container' as React.ElementType;
const ContactButton = 'rvv-contact-button' as React.ElementType;
const Overview = 'rvv-page-overview' as React.ElementType;
const LayoutFull = 'rvv-page-full' as React.ElementType;
const LayoutWithAside = 'rvv-page-with-aside' as React.ElementType;
const Maintenence = 'rvv-maintenence' as React.ElementType;

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<
StoryblokEntryProps,
StoryblokEntryState
> {
  public static getDerivedStateFromProps(
    props: StoryblokEntryProps,
    state: StoryblokEntryState,
  ): StoryblokEntryState {
    return state.story.uuid !== props.pageContext.story.uuid
      ? parseEntryData(props)
      : null;
  }

  public constructor(props: StoryblokEntryProps) {
    super(props);
    this.state = parseEntryData(props);
  }

  public componentDidMount(): void {
    this.maskUrl();

    /** fetch is polyfilled */
    // eslint-disable-next-line compat/compat
    fetch(`/navigation-data-${this.state.story.lang}.json`)
      .then((res) => res.json())
      .then((navigationData) => {
        this.setState({ navigation: navigationData });
      });

    NavigationService.getContactPage(this.state.story.lang).then(
      (contactPage) => this.setState({ contact: contactPage }),
    );

    const ua = window.navigator.userAgent;
    const isIE = ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
    this.setState({ showIEModal: isIE });
  }

  public render(): JSX.Element {
    const {
      story, navigation, globalContent, showIEModal, ...globalConfig
    } = this.state;

    return (
      <>
        <RvvGlobalConfig {...globalConfig}></RvvGlobalConfig>
        <RvvGlobalContent
          globalContent={JSON.stringify(globalContent)}
        ></RvvGlobalContent>
        <Navigation
          tree={navigation}
          getComponent={getComponent}
          countryCode={StoryblokService.getCountryCode(story).countryCode}
          currentCountry={StoryblokService.getCountryCode(story).country}
          currentLanguage={StoryblokService.getCountryCode(story).locale}
          alternates={JSON.stringify(story.alternates)}
        ></Navigation>
        <Container kind='normal'>
          <div slot='content'>
            {story.content.component === 'rvv-page-overview' && (
              <Overview
                printable={story.content.printable}>
                <div slot='top'>
                  {globalContent.maintenance_mode && (
                    <Maintenence></Maintenence>
                  )}
                  {story.content.top?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
                <div slot='main'>
                  {story.content.body?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
                <div slot='aside'>
                  {story.content.aside.map((c) => blokToComponent({ blok: c, getComponent }))}
                </div>
              </Overview>
            )}
            {story.content.component === 'rvv-page-full' && (
              <LayoutFull
                subnavigation={
                  JSON.stringify(story.content.subnavigation) || null
                }
                printable={story.content.printable}
              >
                <div slot='top'>
                  {globalContent.maintenance_mode && (
                    <Maintenence></Maintenence>
                  )}
                  {story.content.top?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
                <div slot='main'>
                  {story.content.body?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
              </LayoutFull>
            )}
            {story.content.component === 'rvv-page-with-aside' && (
              <LayoutWithAside>
                <div slot='top'>
                  {globalContent.maintenance_mode && (
                    <Maintenence></Maintenence>
                  )}
                  {story.content.top?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
                <div slot='main'>
                  {story.content.body?.map((c) => blokToComponent({ blok: c, getComponent })
                  )}
                </div>
                <div slot='aside'>
                  {story.content.aside.map((c) => blokToComponent({ blok: c, getComponent }))}
                </div>
              </LayoutWithAside>
            )}
            {story.content.component === 'page'
              && blokToComponent({ blok: story.content, getComponent })}
          </div>
        </Container>
        <ContactButton
          link={globalContent.contact_button_link}
          name={globalContent.contact_button_name}
        ></ContactButton>
        <Footer
          tree={navigation}
          getComponent={getComponent}
          countryCode={StoryblokService.getCountryCode(story).countryCode}
        ></Footer>
      </>
    );
  }

  private maskUrl(): void {
    const { maskUrl } = this.props.location.state || {};
    return maskUrl ? window.history.replaceState('', '', maskUrl) : undefined;
  }
}
